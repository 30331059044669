.news {
    // margin-top: -65px;
    transform: translateZ(10px);

    .show--settings & {
        transform: unset;
    }


    &__listing {
        margin: 100px 0;
    }

    &__pagination {
        margin-top: 100px;
    }

    .post {
        margin-bottom: 80px;

        &__image {
            padding-right: 30px;
            a::before {
                display: none;
            }
            img {
                border-radius: 15px;
            }

            @include media-breakpoint-down(md) {
                margin-bottom: 30px;
                padding-right: 0;
            }
        }
        &__content {
            margin-top: auto;
        }
        &__icon {
            margin-bottom: 21px;
        }
        &__date {
            color: $purple;
            font-family: $recoleta;
            font-size: 20px;
            line-height: 27px;
        }
        &__title {
            @include title-2();
            color: $black;
            overflow: hidden;
            text-overflow: ellipsis;
            
            // Addition lines for 2 line or multiline ellipsis
            display: -webkit-box !important;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            white-space: normal;
            @include media-breakpoint-down(md) {
                -webkit-line-clamp: 4;
            }
        }
        &__summary {
            @include long-text();
            color: $black;
            margin: 20px 0 0;
            overflow: hidden;
            text-overflow: ellipsis;
            
            // Addition lines for 2 line or multiline ellipsis
            display: -webkit-box !important;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }
        .btn-link--arrow {
            margin-top: 18px;
        }
    }

    .dropdown {
        margin-bottom: 39px;
    }
}

.load-more {
    .spinner-border {
        display: none;
    }

    &.is-loading .spinner-border {
        display: inline-block;
    }
}