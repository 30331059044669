.page-header {
    padding: 230px 0 100px;
    
    position: relative;

    .show--settings & {
        perspective: 1000px;
    }

    &__shape-bg {
        background: $light-grey;
        border-bottom-right-radius: 15px;
        content: '';
        // height: calc(100% + 100px);
        height: 100%;
        left: 0;
        min-height: 570px;
        position: absolute;
        top: -100px;
        width: 60%;
        z-index: -1;
    }

    .container-xl {
        position: relative;
        transform: translateZ(11px);
        z-index: 5;
    }

    &__top {
        position: relative;
    }

    &__title {
        @include main-title();
    }

    &__intro {
        margin-top: 40px;
        padding-right: 50px;
    }

    h1:not(:first-child) {
        margin-top: 1.5em;
    }

    p {
        font-size: 20px;
        line-height: 35px;
        a:not(.btn) {
            background: linear-gradient(to right, rgba($green, 1), rgba($green, 1));
            background-size: 100% 2px;
            background-position: 100% 100%;
            background-repeat: no-repeat;
            font-weight: bold;
            padding-bottom: 3px;
            transition: $default-animation;
            &:hover {
                background-size: 100% 6px;
            } 
        }
    }
    p.lead {
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 1em;
    }

    &__illustration {
        padding-left: 50px;
    }

    @include media-breakpoint-down(lg) {
        padding-top: 130px;
        &__shape-bg {
            // height: 70%;
            width: 70%;
        }
        &__intro {
            margin-top: 0;
            padding-right: 0;
        }

        &__illustration {
            padding-left: 0;
        }

        &__text {
            padding-top: 30px;
        }

        p {
            font-size: 18px;
            line-height: 32px;
        }
        p.lead {
            font-size: 25px;
            line-height: 35px;
        }
        
    }


    @include media-breakpoint-down(sm) {
        padding: 120px 0 60px;
        &__shape-bg {
            height: 70%;
            width: 85%;
        }
        &__intro {
            margin-top: 30px;
            p.lead {
                font-size: 22px;
            }
        }
        &__illustration {
            margin: 0 auto;
            max-width: 280px;
        }
    }

    &__video {
        align-items: center;
        display: flex;
        flex-direction: column;
        position: relative;
        &::before {
            background: $green;
            bottom: 0;
            content: '';
            display: block;
            height: 4px;
            left: 0;
            position: absolute;
            transform: scaleY(0);
            transform-origin: left bottom;
            transition: $default-animation;
            width: 100%;
            z-index: -1;
        }

        svg {
            filter: drop-shadow(0 7px 13px rgba(0,204,102,0.3));
        }

        &.active, &:hover, &:focus {
            color: $white;
            &::before {
                transform: scaleY(1);
                
            }
        }
        span {
            color: $white;
            display: inline-block;
            font-size: 20px;
            font-weight: bold;
            line-height: 31px;
            margin-top: 20px;
        }
    }

    // Homepage Specific
    &--homepage {
        //min-height: 1030px;
        // overflow-x: hidden;
        padding-top: 0;
        
        .page-header__shape-bg {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 15px;
            height: calc(100% + 200px);
            left: unset;
            right: 0;
            top: -250px;
            z-index: -1;
        }
        .page-header__background {
            border-radius: 0 0 15px 0;
            height: 680px;
            left: 0;
            overflow: hidden;
            position: absolute;
            top: 0;
            transform: translateZ(10px);
            width: 100%;

            &::before {
                background: $black;
                content: '';
                height: 100%;
                left: 0;
                opacity: .5;
                position: absolute;
                top: 0;
                width: 100%;
                z-index: 1;
            }

            video {
                background: $black;
                height: auto;
                left: 50%;
                position: absolute;
                min-height: 100%;
                min-width: 100%;
                top: 50%;
                transform: translate(-50%,-50%);
                width: auto;
                z-index: 0;
                @include media-breakpoint-down(md) {
                    height: 100%;
                }
            }

            @include media-breakpoint-up(lg) {
                padding-bottom: 260px;
            }
            @include media-breakpoint-up(xl) {
                width: 92.5vw;
            }
            @include media-breakpoint-down(md) {
                height: 100%;
            }
        }

        .container-xl {
            color: $white;
            min-height: 680px;
            .row {
                min-height: 680px;
            }
            [class*='col-'] {
                align-items: center;
                display: flex;
                &:last-child {
                    justify-content: center;
                }
            }
            @include media-breakpoint-down(md) {
                min-height: unset;
                .row {
                    min-height: unset;
                }
            }
        }
        .page-header__title {
            @include media-breakpoint-down(md) {
                margin-bottom: 45px;
                margin-top: 80px;
            }
        }

        @include media-breakpoint-down(md) {
            margin-bottom: 60px;
        }
        @include media-breakpoint-down(sm) {
            .page-header__shape-bg {
                height: 130%;
            }
        }
    }

     // About Specific
     &--about {
        .page-header__illustration img {
            height: 100%;
            object-fit: contain;
            width: 100%;
        }

        @include media-breakpoint-up(md) {
            .page-header__illustration {
                height: 100%;
                position: absolute;
                img {
                    object-position: right top;
                }
            }
        }
        @include media-breakpoint-up(lg) {
            .page-header__illustration {
                height: unset;
            }
        }

        @include media-breakpoint-down(sm) {
            .page-header__shape-bg {
                height: 50%;
            }
        }
    }

    // Legal Specific
    &--legal {
        padding-bottom: 60px;

        @include media-breakpoint-down(sm) {
            padding-bottom: 30px;
        }
        
        .page-header__shape-bg {
            min-height: 750px;
            @include media-breakpoint-down(lg) {
                min-height: 570px;
            }
        }

        .page-header__title {
            @include media-breakpoint-up(lg) {
                min-width: 770px;
            }
            /* @include media-breakpoint-down(md) {
                margin-bottom: -75px;
            } */
        }

        h1 {
            @include media-breakpoint-down(lg) {
                max-width: 400px;
            }
        }
    }

    // Contact page
    &--contact {
        p {
            margin-bottom: 16px;
        }
        figure {
            float: left;
            margin: 3px 24px 0 0;
        }

        @include media-breakpoint-down(md) {
            figure {
                margin-right: 20px;
            }
        }
    }
}