.industries {
    padding: 160px 0;
    &__title {
        @include title();
        color: $black;
        margin-bottom: 0;
    }
    ul {
        margin-top: 45px;
        li {
            margin-bottom: 30px;
            &:not(:last-child) {
                margin-right: 30px;
            }
            a::before {
                display: none !important;
            }
        }
    }
    @include media-breakpoint-down(md) {
        padding: 80px 0 60px;
        ul {
            margin-top: 20px;
            margin: 20px -10px 0;
            li {
                flex-basis: 50%;
                margin-bottom: 20px;
                max-width: 50%;
                padding: 10px;
                &:not(:last-child) {
                    margin-right: 0;
                }
                .card--industry {
                    min-width: 100%;
                    width: 100%;
                }
            }
        }
    }
}