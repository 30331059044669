.btn {
    font-weight: bold;
}

.btn-primary {
    box-shadow: 0 10px 20px 0 rgba(0,204,102,0.3);
    color: $white;
    &:hover {
        background: $light-green;
        box-shadow: 0 10px 20px 0 rgba(0,204,102,0);
        color: $white;
    }
    &:active, &:focus {
        color: $white;
    }
}

.btn-secondary {
    box-shadow: 0 10px 20px 0 rgba(213,242,241,0.5);
    &:hover {
        background: #E1F5F5;
        box-shadow: 0 10px 20px 0 rgba(213,242,241,0);
    }
}

.btn-link {
    padding: 0 0 3px;
    position: relative;
    text-decoration: none;
    &::after {
        background: $green;
        bottom: 0;
        content: '';
        display: block;
        height: 2px;
        left: 0;
        opacity: .6;
        position: absolute;
        transition: $default-animation;
        width: 100%;
        z-index: 0;
    }
    &:hover {
        &::after {
            height: 9px;
        }
    }

    &--arrow {
        // display: flex;

        // Arrow icon
        &::before {
            background: url('#{$imagePath}/icon-link-arrow.svg') no-repeat;
            content: '';
            height: 16px;
            left: calc(100% + 8px);
            position: absolute;
            top: calc(50% - 8px);
            
            transition: $default-animation;
            width: 18px;
        }
        // Underline
        &::after {
            height: 0;
            transition: unset;
        }
        &:hover {
            &::before {
                transform: translateX(6px);
            }
            &::after {
                height: 2px;
            }
        }
    }
}