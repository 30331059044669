.card {
    .card-body {
        padding: 40px 40px 30px;
    }
    .card-title {
        color: $purple;
        line-height: 22px;
        font-family: $recoleta;
        font-weight: bold;
    }
    .card-text {
        font-size: 20px;
        font-weight: bold;
        line-height: 31px;
    }

    &--industry {
        box-shadow: 0 12px 24px 0 rgba($black,.1);
        min-width: 216px;
        transition: $default-animation;
        width: 216px;
        .card-body {
            padding: 12px 30px 30px;;
            text-align: center;
            h3 {
                @include title-3();
                margin-bottom: 0;
            }
        }
        &:hover,
        &:focus {
            box-shadow: 0 22px 30px 0 rgba($black,.2);
        }
        @include media-breakpoint-down(md)  {
            .card-body {
                padding: 12px 15px 15px;
                h3 {
                    font-size: 16px;
                }
            }
        }
    }

    &--prefooter {
        background: $light-grey;
        border-width: 0;
        height: 100%;
        // min-height: 329px;
    }
}