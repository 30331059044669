body {
    --cc-text: #{$black};

    --cc-btn-primary-bg: #{$green};
    --cc-btn-primary-hover-bg: #{$light-green};
    --cc-btn-primary-text: #fff;

    --cc-btn-secondary-bg: #{$light-blue};
    --cc-btn-secondary-hover-bg: #E1F5F5;

    #cc--main {
        bottom: 0;
        transform: translateZ(14px);
        width: 100%;
    }
    .cc_div {
        
        * {
            font-family: $sofia-pro;
        }
        .cloud #c-txt {
            font-size: 1em;
        }
        .c-bn {
            border-radius: 25px;
            font-weight: bold;
        }
    }

    #s-bl {
        .title {
            font-size: 16px;
        }
    }
    #s-sv-bn {
        visibility: hidden;
    }
}
.show--settings body #cc--main {
    transform: unset;
}