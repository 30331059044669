.prefooter {
    padding-top: 160px;

    [class*='col-'] {
        @include media-breakpoint-down(md) {
            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }
    }
    &__icon {
        margin-bottom: 32px;
        img {
            max-width: 30px;
        }
    }
    &__title {
        @include title-2();
        margin-bottom: 20px;
        @include media-breakpoint-down(md) {
            margin-bottom: 10px;
        }
    }
    &__text {
        @include regular-copy();
        p {
            margin-bottom: 18px;
        }
    }
    &__download-links {
        li:not(:last-child) {
            margin-right: 30px;
            @include media-breakpoint-down(lg) {
                margin-bottom: 20px;
                margin-right: 0;
            }
        }
    }

    @include media-breakpoint-down(lg) {
        padding-top: 140px;
    }
    
    @include media-breakpoint-down(sm) {
        padding-top: 60px;
    }
}