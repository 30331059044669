.case-study {
    position: relative;

    .row {
        @include media-breakpoint-down(md) {
            [class*='col-']:first-child {
                order: 2;
            }
        }
    }

    &__background {
        background: $light-blue;
        border-radius: 0 15px 15px 0;
        left: 0;
        height: 100%;
        position: absolute;
        transform-origin: left center;
        width: 73%;
        z-index: -1;

        @include media-breakpoint-down(lg) {
            width: 89%;
        }
        @include media-breakpoint-down(md) {
            width: calc(100% - 20px);
        }
    }
    &__content {
        padding: 60px 60px 60px 0;
        @include media-breakpoint-down(lg) {
            padding: 40px 30px 40px 0;
        }
        @include media-breakpoint-down(md) {
            padding: 40px 20px 40px 0;
        }
    }
    &__title {
        @include title();
        color: $black;
        margin-bottom: 20px;
    }
    &__text {
        @include regular-copy();
        color: $black;
        margin-bottom: 40px;
        @include media-breakpoint-down(lg) {
            margin-bottom: 20px;
        }
    }
    &__form {
        input {
            background: transparent;
            // margin-bottom: 39px;
            min-width: 401px;
            @include media-breakpoint-down(lg) {
                margin-bottom: 20px;
            }
            @include media-breakpoint-down(md) {
                min-width: 100%;
            }
        }

        .fui-alert.fui-alert-success {
            border: none;
            padding: 0;

            a {
                @extend .btn;
                @extend .btn-primary;
                color: $white !important;
            }
        }
        .fui-btn-container {
            margin-bottom: 30px;
        }
    }
    &__image {
        padding-top: 100px;
        @include media-breakpoint-down(lg) {
            padding-top: 80px;
            img {
                max-width: 278px;
            }
        }
        @include media-breakpoint-down(md) {
            display: flex;
            justify-content: center;
            margin-top: -25px;
            padding-top: 0;
        }
    }
}