.dropdown {
    // position: relative;

    .dropdown-toggle:not(.nav-link) {
        background: transparent;
        border: 2px solid $black;
        border-radius: 5px;
        font-size: 20px;
        font-weight: bold;
        line-height: 24px;
        // margin-bottom: 40px;
        padding: 13px 16px;
        position: relative;
        text-align: left;
        width: 100%;
        &::after {
            display: none;
        }
        &::before {
            background: url('../images/icon-chevron-down.svg');
            content: '';
            height: 16px;
            position: absolute;
            right: 17px;
            top: calc(50% - 8px);
            width: 16px;
        }
        &.show {
            border-color: $green;
            &::before {
                transform: rotate(180deg);
            }
        }
        &:hover {
            border-color: $green;
        }
    }
    .dropdown-menu {
        border: 2px solid $black;
        border-radius: 0;
        padding: 0;
        // position: absolute !important;
        // top: 10px !important;
    
        .dropdown-item {
            font-size: 20px;
            line-height: 27px;
            padding: 9px 15px;
            &.active {
                background: $green;
                color: $white !important;
            }
            &:hover:not(.active) {
                background: $light-blue;
            }
        }

        @include media-breakpoint-down(md) {
            width: 100%;
        }
    }
}