// Colors
$theme-colors: (
    "primary": $green,
    "secondary": $light-blue,
);

// Base font
$font-family-sans-serif: $sofia-pro;
$font-family-bold: $sofia-pro-bold;

// Accordion
$accordion-padding-y: 24px;
$accordion-padding-x: 44px;
$accordion-body-padding-y: 0;
$accordion-body-padding-x: 0;
$accordion-border-width: 0;
$accordion-border-radius: 0;
$accordion-icon-width: 12px;
$accordion-icon-color: $black;
$accordion-icon-active-color: $black;

// Buttons
$btn-padding-y: 16px;
$btn-padding-x: 25px;
$btn-font-family: $font-family-bold;
$btn-font-size: 16px;
$btn-line-height: 18px;

$btn-border-width: 0;

$btn-box-shadow: 0 10px 20px 0 rgba(0,204,102,0.3);

$btn-link-color: $black;
$btn-link-hover-color: $black;

$btn-border-radius: 25px;

// Containers
$container-padding-x: 30px;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1260px
);

$grid-gutter-width: 1.875rem;

// Cards
$card-title-spacer-y: 5px;
$card-border-width: 1px;
$card-border-radius: 15px;
$card-border-color: $grey;
$card-color: $black;

// Headings
$headings-font-family: $recoleta;
$headings-font-weight: normal;

// Navbar
$navbar-padding-y: 60px;
$navbar-nav-link-padding-x: 0;
$navbar-brand-padding-y: 0;
$navbar-light-color: $black;
$navbar-dark-color: $white;
$navbar-dark-hover-color: $white;
$navbar-toggler-padding-y: 0;
$navbar-toggler-padding-x: 0;
$navbar-toggler-border-radius: 0;
$navbar-toggler-focus-width: 0;

// Table
$table-cell-padding-y: 24px;
$table-cell-padding-x: 20px;
$table-cell-vertical-align: middle;
$table-border-width: 1px;
$table-border-color: $grey;