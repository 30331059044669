.timeline {
    &__wrapper {
        position: relative;
    }
    &__path {
        height: calc(100% - 300px);
        left: 100px;
        position: absolute;
        top: 175px;
        width: calc(100% - 200px);
        z-index: 0;
        svg {
            height: 100%;
            width: 100%;
        }
        &--tablet,
        &--mobile {
            left: 0;
            width: 100%;
        }
    }

    .desktop-car {
        offset-path: path('m93.6388913 26.1878538c697.0275847 122.7217882 1037.1600187 285.7417622 1020.3972887 489.0599192-25.14409 304.977236-926.758397 172.815026-1020.3972887 416.455718-93.6388913 243.640689 827.1609167 258.710839 982.9740487 466.570839 103.87541 138.57334-83.662605 247.03748-562.614063 325.39243');
        // animation: followpath 10s linear infinite;
        offset-distance: var(--offset-distance, 0%);
        transition: all .15s linear;
    }

    .tablet-car {
        offset-path: path('m77.0026074 23.2446971c410.8305286 137.3420569 616.2457926 278.0517339 616.2457926 422.1290299 0 216.115944-279.012766 268.559308-453.270818 307.605879-174.2580511 39.046571-112.667483 188.430955-27.87051 245.566683 84.796973 57.135731 418.188155 95.967601 463.821091 208.632571 30.421958 75.10999-49.213763 138.72487-238.907163 190.84465');
        offset-distance: var(--offset-distance, 0%);
    }

    .mobile-car {
        offset-path: path('m56.3149165 18.3142183c174.1773655 71.767663 272.4024005 173.0928127 294.6751035 303.9754497 33.409056 196.323955-186.659199 233.238178-265.421281 328.627471-78.76208247 95.389293 162.822276 203.576839 239.807768 328.229636 76.985493 124.652795-239.807768 207.796695-239.807768 358.480995 0 150.68429 251.101807 216.92834 251.101807 332.0476s-263.0141197 152.93695-263.0141197 301.41333c0 98.98425 92.4445317 197.71845 277.3335937 296.2026');
        offset-distance: var(--offset-distance, 0%);
    }

    @keyframes followpath {
        to {
          offset-distance: 100%;
        }
     }

    &__title {
        @include title();
        margin-bottom: 107px;
        @include media-breakpoint-down(lg) {
            margin-bottom: 60px;
        }
        @include media-breakpoint-down(md) {
            margin-bottom: 20px;
        }
    }
    &__milestone {
        .card {
            box-shadow: 0 12px 24px 0 rgba(0,0,0,0.1);
            flex-basis: 350px;
            width: 350px; // init 380px

            .card-img-bottom {
                height: 150px;
                object-fit: cover;
            }

            @include media-breakpoint-down(xl) {
                flex-basis: 300px;
                width: 300px;
            }
            @include media-breakpoint-down(lg) {
                flex-basis: 29vw;
                width: 29vw;
            }
            @include media-breakpoint-down(md) {
                flex-basis: 278px;
                max-width: 100%;
                width: 278px;
            }
        }
        
        &:not(:first-child) {
            margin-top: -100px;
            @include media-breakpoint-down(md) {
                margin-top: 30px;
            }
        }
        &:nth-child(2n) {
            align-self: center;
            @include media-breakpoint-down(md) {
                align-self: unset;
            }
        }
        &:nth-child(3n) {
            align-self: flex-end;
            margin-top: 50px;
            @include media-breakpoint-down(md) {
                align-self: unset;
            }
        }
        &:nth-child(6n) {
            align-self: center;
            margin-top: -20px;
            @include media-breakpoint-down(md) {
                align-self: unset;
            }
        }
        &:nth-child(7n) {
            align-self: flex-end;
            @include media-breakpoint-down(md) {
                align-self: unset;
            }
        }
        &:nth-child(8n) {
            margin-top: -20px;
        }
        @include media-breakpoint-down(md) {
            margin-top: 30px !important;
            &:nth-child(even) {
                align-self: flex-end;
            }
        }
    }
    &__card-text {
        padding: 40px 40px 30px;
        @include media-breakpoint-down(lg) {
            padding: 30px;
        }
    }
    &__year {
        @include surtitle();
        margin-bottom: 5px;
    }
    &__card-title {
        color: $black;
        font-size: 20px;
        line-height: 24px;
        margin: 0;
    }
    &__card-placeholder {
        background-color: $light-grey;
        height: 120px;
        width: 100%;
    }
}