.richtext {
    h2 {
        @include title();
        margin-bottom: 10px;
    }
    h3 {
        @include title-2();
        margin-bottom: 10px;
    }
    p {
        @include long-text();
        margin-bottom: 30px;
    }
    p.lead {
        @include intro();
        mark {
            font-weight: 700;
        }
    }
    ul, ol {
        margin-bottom: 15px;
        // margin-top: -15px;
        padding-left: 20px;
    }
    li {
        @include long-text();
        &:not(:last-child) {
            margin-bottom: 16px;
        }
    }
    ul li {
        position: relative;
        &::before {
            content: '•';
            left: -20px;
            position: absolute;
        }
    }
    h2 + h3 {
        margin-top: 30px;
    }
    p + h2 {
        margin-top: 45px;
    }
    ul + h3, ol + h3 {
        margin-top: 30px;
    }
}