.logos {
    &__heading {
        @include title();
        margin-bottom: 40px;
    }
    &__list {
        margin: 0 -30px -60px;
        li {
            margin: 0 30px 60px;
            img {
                filter: grayscale(100%);
                max-width: 200px;
            }
        }
        @include media-breakpoint-down(lg) {
            margin: 0 -25px -50px;
            li {
                margin: 0 25px 50px;
                img {
                    max-width: 168px;
                }
            }
        }
        @include media-breakpoint-down(sm) {
            margin: 0 0 -60px;
            li {
                margin: 0 0 60px;
                img {
                    display: inline-block;
                    margin: auto;
                }
            }
        }
    }
}