.tabs-list {
    // margin-bottom: 105px;
    transform: translateZ(10px);
    ul {
        li {
            &:not(:last-child) {
                margin: 0 20px;
            }
            .nav-link {
                color: $black;
                font-size: 20px;
                font-weight: bold;
                line-height: 31px;
                padding: 10px;
                position: relative;

                &:not(.active) {
                    &::after {
                        background: $green;
                        bottom: 6px;
                        content: '';
                        display: block;
                        height: 2px;
                        left: 10px;
                        position: absolute;
                        transition: $default-animation;
                        width: calc(100% - 20px);
                    }

                    &:hover::after {
                        height: 4px;
                    }
                }
                &.active {
                    background: url(#{$imagePath}/tabs-active-bg.svg) no-repeat 0 100%;
                    background-size: 100% 44px;
                }
            }
        }
    }

    &--products,
    &--industries {
        margin-bottom: 105px;
        
    }

    @include media-breakpoint-down(md) {
        ul {
            flex-wrap: nowrap;
            overflow-x: auto;
            li {
                margin: 0;
                .nav-link {
                    font-size: 18px;
                    margin-bottom: 10px;
                    white-space: nowrap;
                }
            }
        }
        &--products,
        &--industries {
            margin-bottom: 60px;
        }
    }
}