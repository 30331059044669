.footer {
    background: $white;
    padding: 60px 0 90px;

    a {
        &:hover {
            &::before {
                height: 2px !important;
                z-index: 1 !important;
            }
        }
    }

    &__main-links {
        li {
            &:not(:last-child) {
                margin-bottom: .5em;
            }

            .title-3 {
                display: block;
                margin-bottom: .5em;
            }

            &:not(:first-child) .title-3 {
                margin-top: 1em;
            }
            a {
                display: inline-block;
                font-size: 20px;
                font-weight: bold;
                line-height: 31px;
                margin-bottom: .5em;
                &::before {
                    transition: unset;
                }
            }
        }
        &--subheader {
            margin-top: 22px;
        }
        &--level-2 {
            li {
                a {
                    font-size: 16px;
                    font-weight: normal;
                    line-height: 24px;
                    margin-bottom: 0;
                }
            }
        }

        @include media-breakpoint-down(lg) {
            max-width: 450px;
            ul {
                &.footer__main-links--last-col {
                    margin: 31px 0 0;
                }
            }
        }
    }

    &__privacy-links {
        // position: relative;
        // top: -60px;
        li {
            &:not(:last-child) {
                margin-right: 30px;
            }
            a {
                font-size: 13px;
            }
        }
        @include media-breakpoint-down(lg) {
            margin-top: 40px;
            top: unset;
        }
    }

    &__lang {
        margin-bottom: 34px;
        li:not(:last-child) {
            margin-right: 35px;
        }
        a {
            &.active {
                font-weight: bold;
            }
        }
        @include media-breakpoint-down(md) {
            margin-top: 40px;
        }
    }

    &__social-media {
        margin-bottom: 30px;
        li {
            align-items: center;
            display: flex;
            &:not(:last-child) {
                margin-bottom: 16px;
            }
            svg {
                height: 24px;
                width: 24px;
            }
            a {
                margin-left: 15px;
            }
        }
        @include media-breakpoint-down(md) {
            margin-top: 20px;
        }
    }

    &__download-links {
        li:not(:last-child) {
            margin-bottom: 16px;
        }
        a:hover {
            &::before {
                display: none;
            }
        }
        img {
            width: 135px;
        }
    }
}