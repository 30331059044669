.article {
    &__header {
        padding: 230px 0 0;
        position: relative;
        &::before {
            background: $light-grey;
            border-bottom-right-radius: 15px;
            content: '';
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 60%;
            z-index: 0;
        }

        .container-xl {
            position: relative;
            z-index: 5;
        }

        @include media-breakpoint-down(lg) {
            padding-top: 133px;
        }
        @include media-breakpoint-down(md) {
            padding-top: 113px;
            .row {
                [class*='col-']:first-child {
                    order: 2;
                }
                [class*='col-']:nth-child(2) {
                    padding: 0;
                }
            }
        }
    }

    &__date {
        color: $purple;
        display: inline-block;
        font-family: $recoleta;
        font-size: 20px;
        line-height: 27px;
        margin-top: 26px;
        @include media-breakpoint-down(md) {
            margin-top: 20px;
        }
    }
    &__title {
        @include title();
        color: $black;
        margin: 0 50px 30px 0;
        @include media-breakpoint-down(md) {
            margin: 0 0 20px;
        }
    }

    &__breadcrumb {
        margin-bottom: 1.5em;
        a {
            color: $green !important; // necessary because of typography.scss
            font-size: 14px;
            font-weight: bold;
            line-height: 24px;
            &:hover {
                color: $black !important;
            }
        }
    }

    &__image {
        margin-top: 2.75em;
        margin-bottom: -2.75em;
        max-width: 722px;
        img {
            border-radius: 15px;
        }
        @include media-breakpoint-down(lg) {
            img {
                border-radius: 15px 0 0 15px;
                max-width: calc(50% - 30px);
                position: absolute;
                right: 0;
            }
        }
        @include media-breakpoint-down(md) {
            margin: 0 -5px 40px;
            img {
                border-radius: 0;
                display: block;
                margin: auto;
                max-width: 100%;
                position: static;
            }
        }
    }

    &__content {
        // @include intro();
        margin: 120px 0 32px;
        overflow: hidden;
        position: relative;

        h1 {
            @include media-breakpoint-down(lg) {
                max-width: 400px;
            }
        }
        h2, h3, a, p , ul, ol {
            @include media-breakpoint-down(lg) {
                max-width: 524px;
            }
        }
        h2 {
            @include title();
            margin-bottom: 10px;
        }
        h3 {
            @include title-2();
            margin-bottom: 10px;
        }
        a {
            color: $green !important;
            font-weight: bold;
            text-decoration: underline;
        }
        p {
            // font-size: 20px;
            // line-height: 1.75;
            // margin-bottom: 1.5em;
            @include long-text();
            margin-bottom: 30px;
        }
        ul, ol {
            margin-bottom: 15px;
            margin-top: -15px;
            padding-left: 20px;
        }
        li {
            @include long-text();
            &:not(:last-child) {
                margin-bottom: 30px;
            }
        }
        ul li {
            position: relative;
            &::before {
                content: '•';
                left: -20px;
                position: absolute;
            }
        }
        h2 + h3 {
            margin-top: 30px;
        }
        p + h2 {
            margin-top: 45px;
        }
        ul + h3, ol + h3 {
            margin-top: 30px;
        }

        // ul {
        //     list-style: disc;
        //     margin-bottom: 1.5em;
        //     padding-left: 32px;
        //     li {
        //         font-size: 20px;
        //         line-height: 1.75;
        //         margin-bottom: .75em;
        //     }
        // }
        /* ul li {
            margin-bottom: 40px;
        } */
    }

    &__pullquote {
        background: $light-blue;
        border-bottom-left-radius: 15px;
        border-top-left-radius: 15px;
        left: 58.5%;
        padding: 60px;
        position: absolute;
        &::after {
            background: $light-blue;
            content: '';
            height: 100%;
            left: 100%;
            position: absolute;
            top: 0;
            width: calc(100vw - (1140px / 2));
        }
        blockquote {
            margin-bottom: 0;
            max-width: 340px;
            p {
                font-family: $recoleta;
                font-size: 24px;
                line-height: 1.25;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        @include media-breakpoint-down(lg) {
            left: 0;
            margin-bottom: 30px;
            position: relative;
            width: calc(100% + 30px);
            blockquote {
                max-width: 475px;
            }
        }
    }
}