.media-text {

    &__image,
    &__video {
        border-radius: 15px;
        overflow: hidden;
    }

    &__video {
        video {
            display: block;
            height: auto;
            width: 100%;
        }

        @include media-breakpoint-down(md) {
           margin-top: 20px;
        }
    }

    &__content {
        padding-right: 30px;
        &.order-md-2 {
            padding-left: 30px;
            padding-right: unset;
        }
        @include media-breakpoint-down(lg) {
            padding-right: calc(var(--bs-gutter-x) * .5);
            &.order-md-2 {
                padding-left: calc(var(--bs-gutter-x) * .5);
            }
        }
    }
    &__icon {
        margin-bottom: 22px;
        img {
            max-width: 42px;
        }
    }

    h1, h2, h3 {
        @include title();
        margin-bottom: 40px;
        @include media-breakpoint-down(lg) {
            margin-bottom: 30px;
        }
    }
    p {
        @include long-text();
        margin-bottom: 1.25em;
    }
    p.xl {
        font-size: 52px;
        line-height: 1.181818;
        font-weight: 700;
        @include media-breakpoint-down(lg) {
            font-size: 35px;
        }
        @include media-breakpoint-down(sm) {
            font-size: 30px;
        }
    }
    p.lead {
        @include intro();
        mark {
            font-weight: 700;
        }
    }

    &--absolute {
        .media-text__media {
            position: relative;
        }
        .media-text__image {
            img {
                height: 100%;
                object-fit: contain;
                width: 100%;
            }
        }
        .media-text__video {
            video {
                height: 100%;
                object-fit: contain;
                width: 100%;
            }
        }

        @include media-breakpoint-up(md) {
            .media-text__image {
                height: 100%;
                position: absolute;
                img {
                    object-position: right center;
                }
            }
        }
        @include media-breakpoint-up(lg) {
            .offset-lg-6 .media-text__text {
                padding-left: 15px;
            }
            .media-text__image {
                height: unset;
            }
        }
        @include media-breakpoint-down(lg) {
            .media-text__text {
                padding-top: 30px;
            }
        }
    }
}