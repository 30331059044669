.highlight {
    background: $light-blue;
    border-radius: 15px;
    color: $black;
    padding: 50px 70px;

    @include media-breakpoint-down(lg) {
        padding: 50px 60px;
    }
    
    @include media-breakpoint-down(md) {
        padding: 50px 30px;
    }

    &__icon {
        margin-bottom: 10px;
        img {
            height: auto;
            width: 56px;
        }
    }
    &__title {
        @include title();
        margin-bottom: 30px;
        @include media-breakpoint-down(md) {
            margin-bottom: 20px;
        }
    }
    &__text {
        @include long-text();
    }
}