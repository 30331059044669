body.menu-opened {
    overflow: hidden;
}

.show--settings header {
    perspective: 1000px;
}

.navbar {
    position: absolute;
    top: 0;
    perspective: 100px;
    transition: transform $default-timing $default-ease;
    transform: translate3d(0px, 0px, 12px);
    width: 100%;
    z-index: 99;

    // Custom Sticky
    &.is-sticky {
        background: $white;
        box-shadow: 0 0 12px 0 rgba($black,.1);
        padding: 30px 0;
        position: fixed;
        // transform: translateZ(12px);
    }
    
    &.is-hidden {
        box-shadow: none;
        transform: translate3d(0px,calc(-100% - 12px), 12px);
    }

    .navbar-brand {
        margin-top: -12px;
        margin-right: 60px;
    }

    // Dark Navbar
    &.navbar-dark {
        #logo-text {
            fill: $white;
        }
        .fill-current path {
            fill: $white;
        }
    }

    &__inner {
        width: 100%;
    }

    
    .navbar-nav .nav-link {
        // color: $black;
        padding: 0 0 3.5px;
        position: relative;
        transition: none;
        
        &::before {
            background: $green;
            bottom: 0;
            content: '';
            display: block;
            height: 2px;
            left: 0;
            opacity: 0;
            position: absolute;
            transform: scaleX(0);
            transform-origin: left center;
            transition: $default-animation;
            width: 100%;
            z-index: 0;
        }
        &.active, &:hover, &:focus {
            // color: $black;
            &::before {
                height: 2px;
                opacity: 1;
                transform: scaleX(1);
                
            }
        }
        &.dropdown-toggle {
            &::before {
                width: calc(100% - 30px);
            }
            &::after {
                display: none;
            }
            svg {
                margin-left: 10px;
                transition: $default-animation;
            }
            &[aria-expanded="true"] {
                &::before {
                    display: block;
                }
                svg {
                    transform: rotate(180deg);
                }
            }
        }
    }
    .dropdown-menu[data-bs-popper] {
        border-radius: 10px;
        border-width: 0;
        box-shadow: 0 12px 24px 0 rgba(0,0,0,0.1);
        min-width: 170px;
        padding: 19px 20px 30px;
        top: calc(100% + 20px);

        li {
            &:not(:last-child) {
                margin-bottom: 20px;
            }
            .dropdown-item {
                color: $black;
                display: inline-block;
                font-family: $sofia-pro;
                font-size: 16px;
                line-height: 24px;
                padding: 0 0 3px;
                position: relative;
                width: unset;
                &::before {
                    display: none;
                }
                &:hover {
                    background-color: unset;
                    &::before {
                        background: $green;
                        bottom: 0;
                        content: '';
                        display: block;
                        height: 2px;
                        left: 0;
                        position: absolute;
                        width: 100%;
                    }
                }
            }
        }
    }
    .navbar-toggler {
        border-width: 0;
    }

    &__main-links {
        .nav-item {
            margin-right: 40px;
            .nav-link {
                font-size: 20px;
                font-weight: bold;
                line-height: 31px;
            }
        }
    }
    &__secondary-links {
        .nav-item {
            &:not(:last-child) {
                margin-right: 30px;
            }
        }
        &.navbar-nav .nav-link {
            font-family: $sofia-pro;
            font-size: 16px;
            line-height: 24px;
            &::before {
                bottom: 1px;
            }
        }
    }

    @include media-breakpoint-down(xl) {
        .navbar-brand {
            margin-right: 50px;
        }
        &__main-links {
            .nav-item {
                margin-right: 30px;
            }
        }
    }

    @include media-breakpoint-down(lg) {
        padding: 30px 0;

        &__inner {
            height: 100vh;
            overflow-y: scroll;
            -webkit-overflow-scrolling: touch;
        }
        .navbar-collapse {
            background: linear-gradient(315deg, #27E184 0%, #5A65F0 100%);
            left: 0;
            position: fixed;
            top: 0;
            width: 100vw;
            z-index: 999;
            .navbar__main-links {
                margin-bottom: 60px;
                padding: 40px 40px 0;
                li {
                    &:not(:last-child) {
                        margin-bottom: 18px;
                    }
                    .nav-link {
                        font-size: 30px;
                        line-height: 46px;
                    }
                }
            }
            .navbar__secondary-links {
                padding: 0 40px 40px;
                li {
                    &:not(:last-child) {
                        margin-bottom: 10px;
                    }
                    .nav-link {
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
            }
            .nav-link {
                color: $white;
                display: inline-block;
                &:hover, &:focus, &.active {
                    color: $white;
                    &::before {
                        background: $white;
                    }
                }
                &.dropdown-toggle {
                    &::before {
                        width: calc(100% - 34px);
                    }
                    svg path {
                        fill: $white;
                    }
                }
            }
            .dropdown-menu {
                background-color: transparent;
                box-shadow: none;
                padding: 0;
                li {
                    &:not(:last-child) {
                        margin-bottom: 0;
                    }
                    .dropdown-item {
                        color: $white;
                        margin-top: 15px;
                        &:hover, &.active {
                            &::before {
                                background: $white;
                            }
                        }
                    }
                }
            }
            .navbar-close {
                color: $white;
                position: absolute;
                right: 40px;
                top: 40px;
                transform: translateX(-100%);
            }
        }
        .navbar-toggler {
            height: 20px;
            z-index: 1000;
            span {
                background: $black;
                border-radius: 2px;
                display: block;
                height: 3px;
                transition: $default-animation;
                width: 20px;
                &:not(:last-child) {
                    margin-bottom: 4px;
                }
            }
            &[aria-expanded="true"] {
                span {
                    background: $white;
                    &:first-child {
                        transform: rotate(45deg) translate(0 , 5px);
                    }
                    &:last-child {
                        transform: rotate(-45deg) translate(0 , -5px);
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        padding-top: 30px;
    }
}

// Homepage
.template-homepage {
    .navbar {
        .navbar-brand {
            #logo-text {
                fill: $white;
            }
        }
        .navbar-nav .nav-link {
            color: $white;
            &.dropdown-toggle {
                svg path {
                    fill: $white;
                }
            }
        }
        .navbar-toggler {
            span {
                background: $white;
            }
            &[aria-expanded="true"] {
                span {
                    background: $white;
                }
            }
        }

        &.is-sticky {
            .navbar-brand {
                #logo-text {
                    fill: $black;
                }
            }
            .navbar-nav .nav-link {
                color: $black;
                &.dropdown-toggle {
                    svg path {
                        fill: $black;
                    }
                }
            }
            .navbar-toggler {
                span {
                    background: $black;
                }
            }
        }
    }

    &.menu-opened .navbar.is-sticky {
        .navbar-brand {
            #logo-text {
                fill: $white;
            }
        }
        .navbar-nav .nav-link {
            color: $white;
            &.dropdown-toggle {
                svg path {
                    fill: $white;
                }
            }
        }
        .navbar-toggler {
            span {
                background: $white;
            }
        }
    }
}