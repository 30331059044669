// Elements
.fui-input-container {
    &:focus-visible {
        outline: none;
    }
}

.fui-row {
    margin: 0 -15px;

    @include media-breakpoint-down(md) {
        display: block !important;
        margin: 0;
    }
}

.fui-field {
    padding: 0 15px 30px;
    &.fui-error {
        .fui-label,
        .fui-legend {
            color: $red;
        }
    }

    @include media-breakpoint-down(md) {
        padding: 0 0 15px;
    }
}

.fui-label,
.fui-legend {
    font-family: $sofia-pro-bold;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 5px;
}

.fui-input, .fui-select  {
    border: 2px solid $black;
    border-radius: 5px;
    font-family: $sofia-pro;
    font-size: 20px;
    line-height: 27px;
    min-width: 266px;
    padding: 10px 16px;
    width: 100%;
    &:hover, &:active, &:focus, &:focus-visible {
        border-color: $green;
        outline: none;
    }

    &.fui-error {
        border-color: $red;
        &:active, &:focus, &:focus-visible {
            border-color: $red;
        }
    }
    &:disabled {
        border-color: $disabled-grey;
        color: #8A8A8F;
        &:hover, &:active, &:focus, &:focus-visible {
            border-color: $disabled-grey;
        }
    }
}

.fui-select {
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    background: url('../images/icon-arrow-down.svg') $white;
    background-repeat: no-repeat;
    background-position-x: calc(100% - 13px);
    background-position-y: calc(100% - 16px);
    padding-right: 36px;
}

// Checkbox / Radio
.fui-checkbox, .fui-radio {
    &:not(:last-child) {
        margin-bottom: 12px;
    }
}
.fui-checkbox-label, .fui-radio-label {
    font-family: $sofia-pro;
    font-size: 16px;
    font-weight: bold;
    line-height: 18px;
    position: relative;
}

.fui-checkbox-input, .fui-radio-input {
    margin-right: 15px;
    min-width: auto;

    cursor: pointer;
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0;
}

.fui-checkbox-label {
    padding-left: 39px;
    &::before {
        background: $white;
        border: 2px solid $black;
        border-radius: 5px;
        content: '';
        cursor: pointer;
        display: inline-block;
        height: 24px;
        left: 0;
        position: absolute;
        top: 0;
        width: 24px;
    }
    &:hover {
        &::before {
            border-color: $light-green;
        }
    }
}

.fui-checkbox-input {
    &:checked {
        + label {
            &::after {
                border: solid $light-green;
                border-width: 0 4px 4px 0;
                content: '';
                left: 7px;
                height: 15px;
                position: absolute;
                top: 3px;
                width: 10px;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }
    }
}

.fui-radio-label {
    padding-left: calc(24px + 15px);
    &::before {
        background: $white;
        border: 2px solid $black;
        border-radius: 50%;
        content: '';
        cursor: pointer;
        display: inline-block;
        height: 24px;
        left: 0;
        position: absolute;
        top: 0;
        width: 24px;
    }
    &:hover {
        &::before {
            border-color: $light-green;
        }
    }
}

.fui-radio-input {
    &:checked {
        + label {
            &::after {
                background: $light-green;
                border-radius: 50%;
                content: '';
                height: 14px;
                left: 5px;
                position: absolute;
                top: 5px;
                width: 14px;
            }
        }
    }
}

.fui-type-agree,
.fui-type-radio-buttons,
.fui-type-checkboxes {
    .fui-error-message {
        color: $red;
        padding-top: 5px;
        padding-left: 39px;
    }
}

// Submit button
.fui-submit {
    background: $green;
    border-radius: 25px;
    border-width: 0;
    box-shadow: 0 10px 20px 0 rgba(0,204,102,0.3);
    color: $white;
    font-size: 16px;
    font-weight: bold;
    line-height: 18px;
    padding: 16px 25px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:hover {
        background: $light-green;
        box-shadow: 0 10px 20px 0 rgba(0,204,102,0);
        color: $white;
    }
    &:active, &:focus {
        color: $white;
    }
}

// Layout
.lead-form {

    &--grey {
        background: $light-grey;
        padding: 100px 0;
    }

    &--white {
        background: $white;
    }

    &__title {
        @include title();
        margin-bottom: 60px;
        text-align: center;
    }
    .fui-i {
        margin: auto;
        max-width: 1200px;
    }
    .fui-row {
        margin: 0 -15px;
    }
    .fui-field {
        margin-bottom: 30px;
        padding: 0 15px;
    }
   /*  .fui-input, .fui-select {
        width: 100%;
    } */
    .fui-submit {
        display: inline-block;
        margin: auto;
    }

    @include media-breakpoint-down(sm) {
        .fui-row {
            margin: 0;
        }
        .fui-field {
            padding: 0;
        }
    }
}

// Messages
.fui-alert {
    border-radius: 15px;
    margin-bottom: 30px;
    padding: 15px;
    h3 {
        font-size: 20px !important;
    }
}

.fui-alert-success {
   border: 2px solid  $light-green;
   color: $green;
}

.fui-alert-error {
    border: 2px solid $red;
    color: $red;
}

// Fix Tel
.iti-mobile .iti--container {
    transform: translateZ(14px);
}
.iti-mobile .iti__flag {
    background-image: url(https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/img/flags@2x.png) !important;
}

// reCAPTCHA
.grecaptcha-badge {
    transform: translateZ(10px);
}