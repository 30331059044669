// Transition
// -------------------------
$defaultTime: 0.5s;
$opacityTime: 0.35s;
$translateTime: 0.65s;
// $defaultEasing: cubic-bezier(0.215, 0.610, 0.355, 1.000);
$defaultEasing: $default-ease;

$animate-delay: 0s;
$animate-slide-offset: 50px;
$animate-zoom-start: .5;

[data-animate] {
  transition-duration: var(--animate-duration, $defaultTime);
  transition-delay: var(--animate-delay, $animate-delay);
  transition-timing-function: var(--animate-easing, $defaultEasing);
}

// Fade
[data-animate|='fade'] {
  opacity: 0;
  transition-property: opacity;
}

[data-animate|='fade'].in-view {
  opacity: 1;
}

// Slide
[data-animate|='slide'] {
  opacity: 0;
  transform: translateY(var(--animate-slide-offset, $animate-slide-offset));
  transition-property: opacity, transform;
  transition-duration: var(--animate-opacity-duration, $opacityTime), var(--animate-slide-duration, $translateTime);
}

// Zoom
[data-animate|='zoom'] {
  opacity: 0;
  transform: scale(var(--animate-zoom-start, $animate-zoom-start));
  transform-origin: center center;
  transition-property: opacity, transform;
}

[data-animate|='slide'].in-view,
[data-animate|='zoom'].in-view {
  opacity: 1;
  transform: none;
}
