.legal {
    &__content {
        margin-top: 60px;
        padding-bottom: 40px;

        @include media-breakpoint-down(sm) {
            margin-top: 30px;
            padding-bottom: 0;
        }

        h2, h3, a, p , ul, ol {
            max-width: 735px;
            @include media-breakpoint-down(lg) {
                max-width: 524px;
            }
        }
        h2 {
            @include title();
            margin-bottom: 10px;
        }
        h3 {
            @include title-2();
            margin-bottom: 10px;
        }
        a {
            color: $green !important;
            font-weight: bold;
            text-decoration: underline;
        }
        p {
            @include long-text();
            margin-bottom: 30px;
        }
        ul, ol {
            margin-bottom: 15px;
            margin-top: -15px;
            padding-left: 20px;
        }
        li {
            @include long-text();
            &:not(:last-child) {
                margin-bottom: 30px;
            }
        }
        ul li {
            position: relative;
            &::before {
                content: '•';
                left: -20px;
                position: absolute;
            }
        }
        h2 + h3 {
            margin-top: 30px;
        }
        p + h2 {
            margin-top: 45px;
        }
        ul + h3, ol + h3 {
            margin-top: 30px;
        }
    }
}