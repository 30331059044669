@include media-breakpoint-down(sm) {
    .container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
        padding-right: 20px;
        padding-left: 20px;
    }
}

#pageContent {
    .page-loader {
        opacity: 0;
        position: absolute;
        transition: opacity .35s $defaultEasing;
        width: 100%;
    }

    &.is-loading {
        .page-loader {
            opacity: 1;
        }
    }
}

.page-content {
    padding-top: 60px;
    position: relative;
    // transform: translateZ(10px);
    // z-index: 10;

    .show--settings & {
        transform: unset;
        z-index: 1;
    }

    &--about {
        padding-bottom: 100px;
        @include media-breakpoint-down(sm) {
            padding-bottom: 60px;
        }
    }

    @include media-breakpoint-down(sm) {
        padding-top: 0;
    }
}
.section:first-child {
    transform: translateZ(10px);
}
.section:not(:first-child) {
    margin-top: 160px;

    @include media-breakpoint-down(lg) {
        margin-top: 100px;
    }
    @include media-breakpoint-down(sm) {
        margin-top: 60px;
    }
}

#launcher {
    transform: translateZ(12px);
    .show--settings & {
        transform: unset;
    }
}