.features-carousel {
    background: $light-grey;
    padding: 100px 0 200px;
    position: relative;

    @include media-breakpoint-down(lg) {
        padding: 100px 0;
    }

    @include media-breakpoint-down(md) {
        overflow-x: hidden;
        padding: 60px 0 30px;
    }

    &__intro {
        margin: 60px 0 80px;
        @include media-breakpoint-down(md) {
            margin: 20px 0 35px;
        }
    }
}

.sticky-slider {
    position: relative;
    
    &__dots {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        ul {
            position: sticky;
            transform: translateY(-25%);
            top: 50vh;
        }

        li {
            background: $grey;
            border-radius: 2px;
            height: 20px;
            margin-bottom: 20px;
            width: 4px;

            &.is-active {
                background: $green;
            }
        }
    }

    @include media-breakpoint-up(md) {
        .glide__slides {
            display: block;
        }
    }
    @include media-breakpoint-down(md) {
        .glide__track {
            margin: 0 -15px;
        }
        .glide__bullets {
            // left: 50%;
            position: absolute;
            text-align: center;
            top: -20px;
            width: 100%;
            // transform: translateX(-50%);
        }
        .glide__bullet {
            background: $black;
            border-radius: 2px;
            border-width: 0;
            display: inline-block;
            height: 4px;
            margin: 0 8px 0 0;
            opacity: .1;
            padding: 0;
            width: 15px;

            &--active {
                background: $green;
                border-radius: 3px;
                height: 6px;
                opacity: 1;
                width: 26px;
            }
        }
        &__text {
            order: 2;
        }
    }

    &__section {
        margin-bottom: 65vh;
        padding-left: 60px;
        padding-top: 35px;
        &:first-child {
            padding-top: 25vh;
            @include media-breakpoint-down(lg) {
                padding-top: 35px;
            }
        }

        &:last-child {
            margin-bottom: 15vh;
            @include media-breakpoint-down(lg) {
                margin-bottom: 0;
            }
        }

        @include media-breakpoint-down(md) {
            display: flex;
            flex-direction: column;
            margin-bottom: 0;
            padding-right: 15px;
            padding-left: 15px;
            text-align: center;
            width: 100%;

            h3 {
                font-size: 18px;
            }
            p {
                font-size: 14px;
            }

            img {
                display: inherit;
                max-width: 200px;
                margin: 0 auto;
            }
        }
    }

    &__images {
        padding-left: 30px;
        position: sticky;
        top: 15%;

        @include media-breakpoint-down(lg) {
            top: 25%;
        }

        &--desktop {
            top: 200px;

            @include media-breakpoint-down(lg) {
                padding-left: 0;
                top: 40%;
            }
        }
    }

    &__devices {
        background: $white;
        border: 7px solid $purple;
        border-radius: 42px;
        box-shadow: 0 12px 24px 0 rgba(#5A65F0,.2);
        height: var(--desktop-height, auto);
        overflow: hidden;
        position: relative;
        transform: translateZ(1px);
        width: var(--desktop-width, 300px);
        z-index: 5;

        &--desktop {
            border-radius: 20px;
            height: 0;
            max-width: 100%;
            margin-top: 15%;
            padding-bottom: var(--desktop-height, 300px);
        }

        @include media-breakpoint-down(lg) {
            max-height: 399px;
            width: var(--tablet-width, 300px);

            &--desktop {
                border-width: 5px;
                margin-top: 0;
                width: 100%;
            }
        }
    }

    &__device {
        height: 100%;
        opacity: 0;
        overflow: hidden;
        // padding-bottom: var(--device-ratio, 100%);
        position: absolute;
        transition: opacity .5s $defaultEasing;
        width: 100%;
        will-change: opacity;

        img {
            height: 100%;
            object-fit: cover;
            width: 100%;
        }

        &.is-active {
            opacity: 1;
        }
    }

    &__users {
        border-radius: 15px;
        height: 92.5%;
        left: 120px;
        overflow: hidden;
        position: absolute;
        top: 15%;
        width: calc(100% - 120px);
        z-index: 2;
        @include media-breakpoint-down(lg) {
            bottom: -15%;
            height: 233px;
            top: unset;
            width: 235px;
        }
    }

    &__user {
        height: 100%;
        opacity: 0;
        position: absolute;
        transition: opacity .5s $defaultEasing;
        width: 100%;

        img {
            height: 100%;
            object-fit: cover;
            width: 100%;
        }

        &.is-active {
            opacity: 1;
        }
    }

    &__mobile-ui {
        padding-bottom: 30px;
        img {
            border: 7px solid $purple;
            border-radius: 24px;
            box-shadow: 0 12px 24px 0 rgba(#5A65F0,.2);
            display: block;
            margin: 0 auto;
        }

        &--desktop {
            img {
                border-radius: 20px;
                border-width: 4px;
                max-width: 90% !important;
            }
        }
        @include media-breakpoint-down(md) {
            order: 1;
        }
    }
}