.contact {
    &__form {
        max-width: 770px;
        position: relative;
        // transform: translateZ(11px);

        h2, .fui-page-container {
            transform: translateZ(10px);
        }

        .show--settings & {
            transform: unset;
        }

        &::before {
            content: '';
            display: block;
            height: 6rem;
            margin-top: -6rem;
        }

        h1, h2 {
            @include title();
            margin-bottom: 30px;
        }
        .fui-field-container {
            textarea {
                min-height: 273px;
            }
        }
        .fui-submit {
            margin-top: 10px;
        }

        @include media-breakpoint-down(lg) {
            max-width: 490px;
            /* .fui-submit {
                margin-top: 0;
            } */
        }
        @include media-breakpoint-down(md) {
            h1, h2, h3 {
                max-width: 325px;
            }
        }
    }
}