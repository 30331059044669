.custom-table {
    @include media-breakpoint-down(md) {
        .container-xl {
            padding: 0;
        }
        
    }

    &__title {
        @include title();
        margin-bottom: 60px;
        text-align: center;
    }

    .accordion {
        .accordion-item {
            &:first-child {
                .accordion-header {
                    .accordion-button {
                        border-radius: 15px 15px 0 0;
                        @include media-breakpoint-down(md) {
                            border-radius: 0;
                        }
                    }
                }
            }
            &:last-child {
                .accordion-header {
                    .accordion-button {
                        border-bottom-width: 0;
                        border-radius: 0 0 15px 15px;
                        @include media-breakpoint-down(md) {
                            border-radius: 0;
                        }
                        &[aria-expanded="true"] {
                            border-bottom-width: 1px;
                            border-radius: 0;
                        }
                    }
                }
            }
            .accordion-header {
                .accordion-button {
                    background: $light-grey;
                    border-bottom: 1px solid $grey;
                    box-shadow: unset;
                    color: $black;
                    font-family: $sofia-pro;
                    font-size: 16px;
                    font-weight: bold;
                    line-height: 19px;
                    text-transform: uppercase;
                    &:not(.collapsed) {
                        background: $light-grey;
                        box-shadow: unset;
                        color: $black;
                        font-family: $sofia-pro;
                        font-size: 16px;
                        font-weight: bold;
                        line-height: 19px;
                        text-transform: uppercase;
                    }
                    &:focus {
                        border-color: $grey;
                        box-shadow: unset;
                    }
                    // Icon
                    &::after {
                        background: url('../images/icon-chevron-down.svg');
                        left: 21px;
                        height: 16px;
                        transform: rotate(0deg);
                        position: absolute;
                        width: 16px;
                    }
                    &[aria-expanded="true"] {
                        &::after {
                            transform: rotate(180deg);
                        }
                    }
                }
            }
        }
    }

    &__header {
        thead {
            th {
                border-width: 0;
                color: $black;
                font-size: 16px;
                font-weight: bold;
                line-height: 19px;
                margin-bottom: -10px;
                padding: 15px 20px 20px;
                text-align: center;
                text-transform: uppercase;
                &:first-child {
                    opacity: 0;
                }
            }
        }

        @include media-breakpoint-down(md) {
            thead th:first-child {
                width: 125px;
            }
        }
    }

    .table {
        margin-bottom: 0;
        table-layout: fixed;
        word-wrap: break-word;
        tbody {
            th {
                color: $black;
                font-size: 14px;
                font-weight: normal;
                line-height: 19px;
                padding: 16px 10px;
                @include media-breakpoint-down(md) {
                    font-size: 12px;
                    width: 125px;
                }
            }
            td {
                color: $black;
                font-size: 12px;
                line-height: 18px;
                padding: 16px 10px;
                text-align: center;
                div {
                    margin: auto;
                }
            }
        }
    }
}
