// Main colours
$black: #000;
$green: #00CC66;
$light-green: #27E184;
$purple: #5A65F0;

// Secondary colours
$orange: #FF8457;
$red: #CC0000;

// Supportive colours
$light-blue: #D5F2F1;
$grey: #E8EAEB;
$light-grey: #F4F6F7;
$disabled-grey: #D7D7D7;
$white: #FFF;