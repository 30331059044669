.modal-backdrop {
    transform: translateZ(12px);
    .show--settings & {
        transform: unset;
    }
}
.modal {
    transform: translateZ(13px);
    .show--settings & {
        transform: unset;
    }
}

.show--settings {
    .modal-backdrop,
    .modal {
        transform: unset;
    }
}

.custom-video-modal,
.custom-announcement-modal {
    .modal-content {
        background: transparent;
        // border-radius: 20px;
        border: 0 solid transparent;
        box-shadow: 0 22px 30px 0 rgba(0,0,0,0.2);
        .modal-header {
            border-width: 0;
            justify-content: flex-end;
            padding: 0;
            .close {
                align-items: center;
                background: $light-grey;
                border-radius: 50%;
                border-width: 0;
                display: flex;
                height: 40px;
                justify-content: center;
                transform: translateZ(12px);
                width: 40px;
                svg {
                    max-width: 16px;
                }
            }
        }
        .modal-body {
            padding: 10px 0 0;
            .responsive-embed {
                border-radius: 20px;
            }
        }
    }
    @include media-breakpoint-down(lg) {
        .modal-content {
            .modal-body {
                padding-top: 20px;
            }
        }
    }
    @include media-breakpoint-down(md) {
        .modal-content {
            border-radius: 10px;
            .modal-body {
                padding-top: 10px;
                .responsive-embed {
                    border-radius: 10px;
                }
            }
        }
    }
}

.custom-announcement-modal {
    .modal-content {
        box-shadow: none;
        .modal-header {
            padding-bottom: 15px;
        }
        .modal-body {
            background: $white;
            border-radius: 20px;
            box-shadow: 0 22px 30px 0 rgba(0,0,0,0.2);
            padding: 30px;
            figure {
                text-align: center;
                img {
                    display: inline-block;
                    height: auto;
                    max-width: 100%;
                }
            }
        }
    }
    
}