/* Video Trigger Button */
.video-trigger {
    span, svg {
        pointer-events: none;
    }
}

/* Responsive Embed */
.responsive-embed {
    background: #010101;
    height: 0;
    overflow: hidden;
    padding-bottom: 56.222222%;
    position: relative;
    width: 100%;
}  
.responsive-embed embed,
.responsive-embed iframe,
.responsive-embed object,
.responsive-embed video {
    border: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 1080px;
    position: absolute; 
}