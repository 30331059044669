.testimonials {
    background: $light-grey;
    padding: 100px 0;

    @include media-breakpoint-down(md) {
        padding: 50px 0;
    }

    .testimonials_carousel {
        .glide__bullets {
            align-items: center;
            display: flex;
            justify-content: center;
            .glide__bullet {
                background: $black;
                border-radius: 2px;
                border-width: 0;
                height: 4px;
                opacity: .1;
                width: 45px;
                &:not(:last-child) {
                    margin-right: 15px;
                }
                &--active {
                    background: $green;
                    border-radius: 3px;
                    height: 6px;
                    opacity: 1;
                    width: 55px;
                }
            }
        }
    }

    .testimonials_slide {
        padding: 60px 60px 65px;
        width: 100%;

        @include media-breakpoint-down(sm) {
            padding: 45px 0;
        }

        blockquote {
            margin-bottom: 0;
        }
        p, cite {
            color: $black;
        }
        p {
            @include long-text();
            &:first-child {
                margin-bottom: 36px;
                position: relative;
                &::before {
                    @include title();
                    content: '“';
                    font-size: 175px;
                    left: -15px;
                    opacity: .04;
                    position: absolute;
                    top: 32px;
                }
                @include media-breakpoint-down(lg) {
                    margin-bottom: 25px;
                    &::before {
                        font-size: 175px;
                    }
                }

                @include media-breakpoint-down(sm) {
                    &::before {
                        font-size: 110px;
                        left: 0;
                        top: 20px;
                    }
                }
            }
        }
        cite {
            @include long-text();
            line-height: 24px;
            font-weight: bold;
            font-style: normal;

            &::before {
                content: "- ";
            }
        }
    }
}