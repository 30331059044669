@mixin main-title {
	font-family: $recoleta;
	font-size: 60px;
	line-height: 62px;
	
	@include media-breakpoint-down(lg) {
		font-size: 50px;
		line-height: 52px;
	}
    @include media-breakpoint-down(sm) {
		font-size: 45px;
		line-height: 50px;
	}
}
.main-title {
	@include main-title();
}

@mixin title {
	font-family: $recoleta;
	font-size: 45px;
	line-height: 55px;
	
	@include media-breakpoint-down(lg) {
		font-size: 40px;
		line-height: 50px;
	}
    @include media-breakpoint-down(sm) {
		font-size: 35px;
		line-height: 40px;
	}
}
.title {
	@include title();
}

@mixin title-2 {
	font-family: $recoleta;
	font-size: 32px;
	line-height: 40px;
	
	@include media-breakpoint-down(lg) {
		font-size: 28px;
		line-height: 38px;
	}
    @include media-breakpoint-down(sm) {
		font-size: 24px;
		line-height: 34px;
	}
}
.title-2 {
	@include title-2();
}

@mixin intro {
	font-family: $sofia-pro;
	font-size: 30px;
	line-height: 40px;
	
	@include media-breakpoint-down(lg) {
		font-size: 25px;
		line-height: 35px;
	}
    @include media-breakpoint-down(sm) {
		font-size: 22px;
		line-height: 32px;
	}
}
.intro {
	@include intro();
}

@mixin long-text {
	font-family: $sofia-pro;
	font-size: 20px;
	line-height: 35px;
	
	@include media-breakpoint-down(lg) {
		font-size: 18px;
		line-height: 32px;
	}
    @include media-breakpoint-down(sm) {
		font-size: 16px;
		line-height: 30px;
	}
}
.long-text {
	@include long-text();
}

@mixin title-3 {
    font-family: $sofia-pro-bold;
    font-size: 20px;
    font-weight: bold;
    line-height: 26px;
}
.title-3 {
    @include title-3();
}

@mixin surtitle($size: 16px) {
    color: $purple;
    font-family: $recoleta;
    font-size: $size;
    line-height: 22px;
}
.surtitle {
	@include surtitle();
}

@mixin regular-copy {
    font-family: $sofia-pro;
    font-size: 16px;
    line-height: 24px;
}
.regular-copy {
	@include regular-copy();
}

a {
	text-decoration: none;
}
a:not(.btn):not(.navbar-brand):not(.nav-link):not(.video-trigger) {
    color: $black;
    // line-height: 18px;
    // padding: 0 0 3px;
    position: relative;
    
    /*&::before {
        background: $green;
        bottom: 0;
        content: '';
        display: block;
        height: 2px;
        left: 0;
        opacity: .6;
        position: absolute;
        transition: $default-animation;
        width: 100%;
        z-index: -1;
    }
    &:hover {
        color: $black;
        &::before {
            height: 9px;
        }
    }*/
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

mark {
	background: url(#{$imagePath}/mark-bg.svg) no-repeat 50% 100%;
	background-size: 100% 30px;
	font-weight: 700;
}