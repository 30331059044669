.feature {
    position: relative;
    &__background {
        background: $light-blue;
        border-radius: 0 15px 15px 0;
        left: 0;
        height: 100%;
        position: absolute;
        // top: 40px;
        // width: 1295px;
        transform-origin: left center;
        width: 80%;
        z-index: -1;
    }
    &__text {
        padding: 50px 30px 50px 0;
        transform: translateZ(10px);
        h1, h2, h3 {
            @include title();
            color: $black;
            margin-bottom: 15px;
        }
        p {
            @include regular-copy();
            color: $black;
            margin-bottom: 14px;
        }
    }
    &__image {
        border-radius: 15px;
        max-width: 410px;
        overflow: hidden;
        position: absolute;
        top: 0;
    }

    @include media-breakpoint-down(lg) {
        &__text {
            padding-right: 0;
        }
        &__image {
            top: 25%;
        }
    }

    @include media-breakpoint-down(sm) {
        margin-bottom: 160px;
        &__background {
            width: calc(100% - 20px);
        }
        &__image {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            max-width: 240px;
            right: -5px;
            top: -50px;
        }
    }
}