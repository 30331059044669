.homepage-introduction {
    /*transform: translateY(-118px);
    @include media-breakpoint-down(md) {
        transform: translateY(-298px);
    }*/

    margin: -118px 0 240px;

    &__video {
        border-radius: 15px;
        overflow: hidden;

        video {
            display: block;
            height: auto;
            width: 100%;
        }
    }
    &__text {
        color: $black;
        font-size: 55px;
        // font-size: 45px;
        font-weight: bold;
        line-height: 1.181818181818182;
        padding-left: 30px;
        p:not(:last-child) {
            margin-bottom: .75em;
        }
        a:not(.btn) {
            background: linear-gradient(to right, rgba($green, 1), rgba($green, 1));
            background-size: 100% 3px;
            background-position: 100% 98%;
            background-repeat: no-repeat;
            transition: $default-animation;
            &:hover {
                background-size: 100% 9px;
            } 
        }
        @include media-breakpoint-down(lg) {
            font-size: 33px;
            line-height: 39px;
            p {
                margin-bottom: 30px;
            }
            a {
                &::before {
                    bottom: -10px !important;
                }
            }
        }
        @include media-breakpoint-down(md) {
            font-size: 30px;
            line-height: 35px;
            margin-bottom: 20px;
        }
    }
    @include media-breakpoint-down(sm) {
        margin-top: 0;
        margin-bottom: 60px;
    }
}

.homepage-feature {
    position: relative;
    &__background {
        background: $light-blue;
        border-radius: 0 15px 15px 0;
        left: 0;
        height: 100%;
        position: absolute;
        // top: 40px;
        // width: 1295px;
        transform-origin: left center;
        width: 80%;
        z-index: -1;
        @include media-breakpoint-down(md) {
            width: calc(100% - 20px);
        }
    }
    &__text {
        padding: 50px 30px 50px 0;
        h1, h2, h3 {
            @include title();
            color: $black;
            margin-bottom: 15px;
        }
        p {
            @include regular-copy();
            color: $black;
            margin-bottom: 14px;
        }
    }
    &__image {
        border-radius: 15px;
        max-width: 410px;
        overflow: hidden;
        position: absolute;
        top: 0;
    }

    @include media-breakpoint-down(lg) {
        &__text {
            padding-right: 0;
        }
        &__image {
            top: 25%;
        }
    }

    @include media-breakpoint-down(sm) {
        margin-bottom: 100px;
        &__background {
            width: calc(100% - 20px);
        }
        &__image {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            max-width: 240px;
            right: -5px;
            top: -50px;
        }
    }
}

.homepage-industries {
    padding: 160px 0;
    &__title {
        @include title();
        color: $black;
        margin-bottom: 0;
    }
    ul {
        margin-top: 45px;
        li {
            margin-bottom: 30px;
            &:not(:last-child) {
                margin-right: 30px;
            }
            a::before {
                display: none !important;
            }
        }
    }
    @include media-breakpoint-down(md) {
        padding: 80px 0 60px;
        ul {
            margin-top: 20px;
            li {
                margin-bottom: 20px;
                &:not(:last-child) {
                    margin-right: 0;
                }
                .card--industry {
                    min-width: 100%;
                    width: 100%;
                }
            }
        }
    }
}
.homepage-news {
    background: $light-grey;
    overflow-x: auto;
    padding: 80px 0;

    &__post {
       margin-right: 60px;  
       min-width: calc(33.33333% - 60px);
       width: calc(33.33333% - 60px); 
       &:last-child {
            padding-right: 30px;
       }

        @include media-breakpoint-down(lg) {
            min-width: 40vw;
            &:last-child {
                min-width: calc(40vw + 30px);
            }
        }
        @include media-breakpoint-down(md) {
            min-width: calc(100vw - 110px);
            &:last-child {
                min-width: calc(100vw - 80px);
            }
        }
        /*@include media-breakpoint-down(sm) {
            min-width: calc(100vw - 35px);
        }*/
    }
    .post {
        &__date {
            @include surtitle();
            display: inline-block;
            margin-top: 20px;
        }
        &__title {
            color: $black;
            font-size: 20px;
            font-weight: bold;
            line-height: 31px;
        }
        &__summary {
            @include regular-copy();
            color: $black;
            margin-top: 15px;
        }
        .btn-link--arrow {
            margin-top: 14px;
        }
    }
}