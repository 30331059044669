.steps-carousel {
    background: $light-grey;
    padding-bottom: 83px;
    @include media-breakpoint-down(md) {
        padding: 60px 0;
    }

    &__title {
        @include title();
        // background: $light-grey;
        // margin-bottom: calc(100vh - 175px);
        padding:  60px 0;
        // position: sticky;
        top: 0;
        z-index: 3;

        @include media-breakpoint-down(md) {
            padding: 0 0 20px;
            position: static;
        }
    }

    .sticky-slider {
        &__section {
            min-height: 449px;
            &:last-child {
                // padding-bottom: 15vh;
                margin-bottom: 5vh;
                @include media-breakpoint-down(md) {
                    padding-bottom: 0;
                }
            }

            @include media-breakpoint-down(sm) {
                margin-bottom: 0;
            }
        }
        &__images {
            display: flex;
            flex-direction: column;
            height: calc(100vh - 175px);
            justify-content: center;
            // top: 175px;
        }

        &__surtitle {
            @include surtitle(22px);
            margin-bottom: 20px;
            @include media-breakpoint-down(md) {
                text-align: center;
            }
        }
        &__text {
           /*  h1, h2, h3 {
                @include title-2();
                margin-bottom: 20px;
            }
            p {
                @include long-text();
            } */
            @include media-breakpoint-down(md) {
                margin-bottom: 10px;
                text-align: center;

                h3 {
                    font-size: 20px;
                    line-height: 26px;
                }
                p.lead {
                    font-size: 18px;
                    line-height: 22px;
                }
                ol, ul {
                    padding-left: 0;
                    li {
                        line-height: 1.2;
                    }
                }
                ol {
                    counter-reset: ol-counter;
                    list-style: none;
                    li {
                        counter-increment: ol-counter;

                        &::before {
                            content: counter(ol-counter) ". ";
                        }
                    }
                }
                ul li::before {
                    display: inline-block;
                    left: 0;
                    margin-right: 10px;
                    position: relative;
                }
            }
        }
        &__illustrations {
            height: 100%; // is flexible in featuresCarousel
            position: relative;
        }
        &__device {
            align-items: center;
            display: flex;
            justify-content: center;
            img {
                object-fit: contain;
            }
            @include media-breakpoint-down(lg) {
                border-width: 0;
            }
        }

        &__mobile-ui {
            margin: 20px auto 0;
            max-width: 280px;
            img {
                border: none;
                border-radius: 0;
                box-shadow: none;
            }
        }
    }
}