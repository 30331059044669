// Recoleta Bold
@font-face {
    font-family: "Recoleta-Bold";
    src: url('../fonts/RecoletaBold/font.woff2') format('woff2'), url('../fonts/RecoletaBold/font.woff') format('woff');
}

$recoleta:  "Recoleta-Bold", serif;

// Sofia Pro
$sofia-pro: sofia-pro, sans-serif;
$sofia-pro-bold: sofia-pro, sans-serif;

@mixin sofia-reg($size: 1em) {
    font-family: $sofia-pro;
    font-style: normal;
    font-size: $size;
    font-weight: 400;
}

@mixin sofia-bold($size: 1em) {
    font-family: $sofia-pro;
    font-style: normal;
    font-size: $size;
    font-weight: 700;
}